import React, { useEffect, useState } from "react";
import Wrapper from "../../../components/Wrapper";
import Footer from "../../../components/Footer";
import WhatsappChat from "../../../components/WhatsappChat";
import Bike from "../../../images/products/adBike.png";
import { localStorageKeys } from "../../../utility/localstorage";
import { getTranslatedText } from "../../../utility/constant";
import ProductPrefernceBanner from "../../../components/ProductPrefernceBanner";

const BikesPageIndex = ({}) => {
  const [myCustomAppState, setMyCustomAppState] = useState({
    language: "English",
  });

  useEffect(() => {
    setMyCustomAppState({
      language: localStorage.getItem(localStorageKeys.language) || "English",
    });
  }, []);

  return (
    <Wrapper
      myCustomAppState={myCustomAppState}
      setMyCustomAppState={setMyCustomAppState}
    >
      <ProductPrefernceBanner
        myCustomAppState={myCustomAppState}
        setMyCustomAppState={setMyCustomAppState}
        headlineOne="Start Riding Your"
        headlineTwo="Honda CD 70"
        headlineThreeDown="by paying only"
        markHeadlineDown="Rs 6,037/Month"
        backgroundColorDown="#007290"
        displayUp="none"
        descpOne="CreditPer has the perfect offer for you."
        descpTwo="With 3 simple steps, you can drive your Honda CD-70."
        bikeImage={Bike}
      />

      <Footer
        getTranslatedText={(key, asElement) =>
          getTranslatedText(myCustomAppState.language, key, asElement)
        }
      />
      <WhatsappChat />
    </Wrapper>
  );
};

export default BikesPageIndex;
